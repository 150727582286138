require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

window.jQuery = $
window.$ = $

import "lodash";

import axios from 'axios';

import "bootstrap";
import "jquery-ui";

require("local-time").start()
import 'data-confirm-modal'

import '../javascript/theme_canvas'

document.addEventListener("DOMContentLoaded", function() {

  (function() {
      "use strict";
      if (window.location && window.location.hash) {
          if (window.location.hash === '#_=_') {
              window.location.hash = '';
              return;
          }
          var facebookFubarLoginHash = RegExp('_\=_', 'g');
          window.location.hash = window.location.hash.replace(facebookFubarLoginHash,     '');
      }
  }()); 
  
  document.querySelectorAll("action-text-attachment").forEach(function(i){
    let src = i.getAttribute("url");
    if(src.indexOf('img.youtube.com/vi/') >= 0){
      let youtube_id = src.split("img.youtube.com/vi/").pop().split("/0.jpg")[0];
      let embed = new DOMParser().parseFromString(`<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, 'text/html').body.firstChild;
      i.replaceWith(embed);
    };
  })

  gon.favorites.forEach(function(i){
    $(`.v-favorite[data-product_id='${i.product_id}']`).addClass("active");
  })

});
